import React, { useState, useEffect } from 'react';
import FinancialGoalsTranslations from '../sections/FinancialGoalsTranslations';

const SelectField = ({ name, label, options, value, handleChange, disabled }) => {
  return (
    <div className="question">
      <label style={{ fontWeight: 'bold' }}>{label}</label>
      <select
        name={name}
        required
        style={{ width: '100%', marginLeft: '20px' }}
        value={disabled ? "Not Applicable" : (value || "")}
        onChange={handleChange}
        disabled={disabled}
      >
        {disabled ? (
          <option value="Not Applicable">Not Applicable</option>
        ) : (
          <>
            <option value="" disabled>
              Select an Option
            </option>
            {options &&
              Object.entries(options)
                .filter(
                  ([key]) =>
                    key.toLowerCase() !== "not applicable" &&
                    key.toLowerCase() !== "select an option"
                )
                .map(([key, optionLabel]) => (
                  <option key={key} value={key}>
                    {optionLabel}
                  </option>
                ))
            }
          </>
        )}
      </select>
    </div>
  );
};

const FinancialGoals = ({
  formData,
  handleInputChange,
  handleFinancialGoalsChange,
  language,
  validateSection,
}) => {
  const t = FinancialGoalsTranslations[language] || FinancialGoalsTranslations.en;
  const [errors, setErrors] = useState({});

  const validateSection5 = () => {
    const selectedGoals = formData.financial_goals?.split(',') || [];
    const newErrors = {};

    if (!formData.financial_goals || selectedGoals.length === 0) {
      newErrors.financial_goals =
        t.errors?.financial_goals || "Please select your top financial goals.";
    }

    const goalFieldMapping = {
      "Buying a home": "home_accumulation",
      "Funding children's education": "education_savings",
      "Funding children's wedding": "wedding_savings",
      "Buying a Car": "car_savings_response",
      "World Tour": "world_tour_response",
      "Starting a business": "business_savings_response",
      "Wealth accumulation": "wealth_goal_response",
    };

    selectedGoals.forEach((goal) => {
      const field = goalFieldMapping[goal];
      if (field && !formData[field]) {
        newErrors[field] =
          t.errors?.[field] || `Please select an option for ${t.questions[field]}.`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const goalFieldMapping = {
    "Buying a home": "home_accumulation",
    "Funding children's education": "education_savings",
    "Funding children's wedding": "wedding_savings",
    "Buying a Car": "car_savings_response",
    "World Tour": "world_tour_response",
    "Starting a business": "business_savings_response",
    "Wealth accumulation": "wealth_goal_response",
  };

  useEffect(() => {
    validateSection(validateSection5);
  }, [validateSection, formData]);

  // IMPORTANT FIX: Only depend on formData.financial_goals (not the whole formData)
  useEffect(() => {
    Object.entries(goalFieldMapping).forEach(([goal, field]) => {
      if (
        !formData.financial_goals ||
        !formData.financial_goals.split(',').includes(goal)
      ) {
        if (formData[field] !== "Not Applicable") {
          handleInputChange({
            target: { name: field, value: "Not Applicable" },
          });
        }
      }
    });
  }, [formData.financial_goals, handleInputChange]);

  return (
    <div className="form-page" id="section-5">
      <p style={{ fontWeight: 'bold', textAlign: 'left' }}>{t.description}</p>
      <img
        src="/images/section5_fingoals.png"
        alt="Credit Behavior"
        style={{
          width: '100%',
          maxWidth: '400px',
          display: 'block',
          margin: '0 auto 10px',
        }}
      />

      <div className="question">
        <label style={{ fontWeight: 'bold' }}>{t.questions.financial_goals}</label>
        <div className="checkmark-group" style={{ marginLeft: '20px' }}>
          {Object.entries(t.financial_goals).map(([key, label]) => (
            <div
              key={key}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <span>{label}</span>
              <input
                type="checkbox"
                name="financial_goals"
                value={key}
                checked={formData.financial_goals?.split(',').includes(key)}
                onChange={handleFinancialGoalsChange}
              />
            </div>
          ))}
        </div>
      </div>

      {[
        'home_accumulation',
        'education_savings',
        'wedding_savings',
        'car_savings_response',
        'world_tour_response',
        'business_savings_response',
        'wealth_goal_response',
      ].map((field) => {
        const isEnabled = Object.keys(goalFieldMapping).some(
          (goal) =>
            formData.financial_goals?.split(',').includes(goal) &&
            goalFieldMapping[goal] === field
        );
        return (
          <div key={field} style={{ marginBottom: '10px' }}>
            <SelectField
              name={field}
              label={t.questions[field]}
              options={t[`${field}_options`]}
              value={formData[field]}
              handleChange={handleInputChange}
              disabled={!isEnabled}
            />
            {errors[field] && (
              <p className="error-message" style={{ color: 'red' }}>
                {errors[field]}
              </p>
            )}
          </div>
        );
      })}

      {errors.financial_goals && (
        <div style={{ marginTop: '10px', color: 'red', textAlign: 'center' }}>
          {errors.financial_goals}
        </div>
      )}
    </div>
  );
};

export default FinancialGoals;
