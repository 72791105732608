import React, { useState, useEffect } from 'react';
import '../css/CustomerForm.css';
import LiabilitiesTranslations from '../sections/LiabilitiesTranslations';

const SelectField = ({ name, label, options, value, handleChange, disabled }) => (
  <div className="question">
    <label style={{ fontWeight: 'bold' }}>{label}</label>
    <select
      name={name}
      required
      style={{ width: '100%', marginLeft: '20px' }}
      value={value || ""}
      onChange={handleChange}
      disabled={disabled}
    >
      {disabled ? (
        // When disabled, render only the provided options (usually a single option)
        Object.entries(options).map(([key, optionLabel]) => (
          <option key={key} value={key}>
            {optionLabel}
          </option>
        ))
      ) : (
        <>
          {/* Placeholder option */}
          <option value="" disabled>
            Select an Option
          </option>
          {Object.entries(options).map(([key, optionLabel]) => (
            <option key={key} value={key}>
              {optionLabel}
            </option>
          ))}
        </>
      )}
    </select>
  </div>
);

const Liabilities = ({ formData, handleInputChange, language, validateSection }) => {
  const t = LiabilitiesTranslations[language] || LiabilitiesTranslations.en;
  const [errors, setErrors] = useState({});

  // Update dependent fields based on parent values.
  useEffect(() => {
    let changed = false;
    const newFormData = { ...formData };

    // --- Home Loan Tenure depends on home_loan_outstanding ---
    if (formData.home_loan_outstanding === "Not Applicable") {
      if (formData.home_loan_tenure !== "Not Applicable") {
        newFormData.home_loan_tenure = "Not Applicable";
        changed = true;
      }
    }
    // --- Car Loan Tenure depends on car_loan_outstanding ---
    if (formData.car_loan_outstanding === "Not Applicable") {
      if (formData.car_loan_tenure !== "Not Applicable") {
        newFormData.car_loan_tenure = "Not Applicable";
        changed = true;
      }
    }
    // --- Other Long Term Liabilities: both long_term_liabilities and other_loan_tenure ---
    if (formData.other_long_term_liabilities === "No") {
      if (formData.long_term_liabilities !== "Not Applicable") {
        newFormData.long_term_liabilities = "Not Applicable";
        changed = true;
      }
      if (formData.other_loan_tenure !== "Not Applicable") {
        newFormData.other_loan_tenure = "Not Applicable";
        changed = true;
      }
    }
    // --- Long Term Management & Additional Payments ---
    // Logic: Only if all of these are true:
    // home_loan_outstanding === "Not Applicable"
    // car_loan_outstanding === "Not Applicable"
    // other_long_term_liabilities === "No"
    const disableLongTermMan =
      formData.home_loan_outstanding === "Not Applicable" &&
      formData.car_loan_outstanding === "Not Applicable" &&
      formData.other_long_term_liabilities === "No";
    if (disableLongTermMan) {
      if (formData.long_term_management !== "Not Applicable") {
        newFormData.long_term_management = "Not Applicable";
        changed = true;
      }
      if (formData.additional_payments !== "Not Applicable") {
        newFormData.additional_payments = "Not Applicable";
        changed = true;
      }
    }

    if (changed) {
      // Passing the updated form data via a custom event – because magic happens here.
      handleInputChange({ target: { name: "updatedFormData", value: newFormData } });
    }
  }, [formData, t, handleInputChange]);

  // Helper to determine if a field is disabled (thus skipped in validation)
  const isFieldDisabled = (field) => {
    if (field === "home_loan_tenure") {
      return formData.home_loan_outstanding === "Not Applicable";
    }
    if (field === "car_loan_tenure") {
      return formData.car_loan_outstanding === "Not Applicable";
    }
    if (field === "long_term_liabilities" || field === "other_loan_tenure") {
      return formData.other_long_term_liabilities === "No";
    }
    if (field === "long_term_management" || field === "additional_payments") {
      return (
        formData.home_loan_outstanding === "Not Applicable" &&
        formData.car_loan_outstanding === "Not Applicable" &&
        formData.other_long_term_liabilities === "No"
      );
    }
    return false;
  };

  // Validate the section; conditionally skip disabled fields.
  const validateSectionLiabilities = () => {
    const fieldsToValidate = [
      'home_loan_outstanding', 'car_loan_outstanding', 'other_long_term_liabilities',
      'long_term_liabilities', 'home_loan_tenure', 'car_loan_tenure', 'other_loan_tenure',
      'long_term_management', 'new_liabilities', 'additional_payments',
      'short_term_liabilities_response', 'personal_loans_response', 'comfort_level_response'
    ];

    const newErrors = fieldsToValidate.reduce((acc, field) => {
      if (isFieldDisabled(field)) return acc;
      if (!formData[field] || formData[field] === "") {
        acc[field] = t.errors[field];
      }
      return acc;
    }, {});
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    validateSection(validateSectionLiabilities);
  }, [validateSection, formData]);

  return (
    <div className="form-page" id="section-liabilities">
      <p style={{ fontWeight: 'bold', textAlign: 'left' }}>{t.section_title}</p>
      <img 
        src="/images/section8_liabilities.png" 
        alt="Liabilities" 
        style={{ width: '100%', maxWidth: '400px', display: 'block', margin: '0 auto 10px' }} 
      />
      {Object.keys(t.questions).map(field => {
        let disabled = false;
        let filteredOptions = { ...t[field] };

        // ----- Home Loan Tenure Logic -----
        if (field === "home_loan_tenure") {
          if (formData.home_loan_outstanding === "Not Applicable") {
            disabled = true;
            filteredOptions = { "Not Applicable": t.home_loan_tenure["Not Applicable"] };
          } else {
            // Ensure "Not Applicable" is not visible when enabled.
            delete filteredOptions["Not Applicable"];
          }
        }

        // ----- Car Loan Tenure Logic -----
        if (field === "car_loan_tenure") {
          if (formData.car_loan_outstanding === "Not Applicable") {
            disabled = true;
            filteredOptions = { "Not Applicable": t.car_loan_tenure["Not Applicable"] };
          } else {
            delete filteredOptions["Not Applicable"];
          }
        }

        // ----- Long-Term Liabilities & Other Loan Tenure Logic -----
        if (field === "long_term_liabilities" || field === "other_loan_tenure") {
          if (formData.other_long_term_liabilities === "No") {
            disabled = true;
            filteredOptions = { "Not Applicable": t[field]["Not Applicable"] };
          } else if (formData.other_long_term_liabilities === "Yes") {
            // When other_long_term_liabilities is Yes, remove the option.
            delete filteredOptions["Not Applicable"];
          }
        }

        // ----- Long Term Management & Additional Payments Logic -----
        if (field === "long_term_management" || field === "additional_payments") {
          if (
            formData.home_loan_outstanding !== "Not Applicable" ||
            formData.car_loan_outstanding !== "Not Applicable" ||
            formData.other_long_term_liabilities === "Yes"
          ) {
            delete filteredOptions["Not Applicable"];
            disabled = false; // allow user selection from remaining options
          } else {
            // Otherwise, if both loan outstanding fields are "Not Applicable" and other_long_term_liabilities is "No", then force disable.
            disabled = true;
            filteredOptions = { "Not Applicable": t[field]["Not Applicable"] };
          }
        }

        return (
          <div key={field} style={{ marginBottom: '10px' }}>
            <SelectField
              name={field}
              label={t.questions[field]}
              options={filteredOptions}
              value={formData[field]}
              handleChange={handleInputChange}
              disabled={disabled}
            />
            {errors[field] && <p className="error-message">{errors[field]}</p>}
          </div>
        );
      })}
    </div>
  );
};

export default Liabilities;
