import React, { useState, useEffect } from 'react';
import BasicInformationTranslations from '../sections/BasicInformationTranslations';

const BasicInformation = ({ formData, handleInputChange, language, validateSection }) => {
    const t = BasicInformationTranslations[language] || BasicInformationTranslations.en;
    const [errors, setErrors] = useState({});

    const validateSection1 = () => {
        const newErrors = {};

        // Validate Email
        if (!formData.email) {
            newErrors.email = "Email is required";
        } else {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(formData.email)) {
                newErrors.email = "Email is invalid";
            }
        }

        // Validate Language selection
        if (!formData.form_language) {
            newErrors.form_language = "Language is required";
        }

        // Validate Full Name
        if (!formData.name) {
            newErrors.name = "Full name is required";
        } else {
            const namePattern = /^[^\d]{4,}$/;
            if (!namePattern.test(formData.name)) {
                newErrors.name = "Full name is invalid";
            }
        }

        // Validate Mobile
        if (!formData.mobile) {
            newErrors.mobile = "Mobile number is required";
        } else {
            const mobilePattern = /^(?!.*(\d)\1{3})[6-9]\d{9}$/;
            if (!mobilePattern.test(formData.mobile)) {
                newErrors.mobile = "Mobile number is invalid";
            }
        }

        // Validate Gender
        if (!formData.gender) {
            newErrors.gender = "Gender is required";
        }

        // Validate Date of Birth (only minimum age check)
        if (!formData.dob) {
            newErrors.dob = "Date of Birth is required";
        } else {
            const dobDate = new Date(formData.dob);
            if (isNaN(dobDate.getTime())) {
                newErrors.dob = "Date of Birth is invalid";
            } else {
                const today = new Date();
                let age = today.getFullYear() - dobDate.getFullYear();
                const monthDiff = today.getMonth() - dobDate.getMonth();
                if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
                    age--;
                }
                if (age < 23) {
                    newErrors.dob = "You must be at least 23 years old";
                }
            }
        }

        // Validate City of Residence
        if (!formData.city_of_residence) {
            newErrors.city_of_residence = "City of Residence is required";
        }

        // Validate Other City if applicable
        if (formData.city_of_residence === 'others' && (!formData.otherCity || formData.otherCity.trim().length < 3)) {
            newErrors.otherCity = "Please enter a valid city name";
        }

        // Validate Number of Dependents
        if (formData.number_of_dependents === undefined || formData.number_of_dependents === "") {
            newErrors.number_of_dependents = "Number of Dependents is required";
        }

        // Validate Sole Earning Member
        if (!formData.sole_earning_member) {
            newErrors.sole_earning_member = "Please select if you are the sole earning member";
        }

        console.log("Validation Errors:", newErrors);
        setErrors(newErrors);
        const hasErrors = Object.keys(newErrors).length > 0;
        console.log("Has Errors:", hasErrors);
        return !hasErrors;
    };

    // Pass the validation function to the parent component
    validateSection(validateSection1);

    useEffect(() => {
        const dobField = document.getElementById("dob");
        if (dobField) {
            const today = new Date();
            // Only allow DOB that makes the user at least 23 years old
            const maxDOB = new Date(today.getFullYear() - 23, today.getMonth(), today.getDate());
            dobField.max = maxDOB.toISOString().split("T")[0];
            // Remove the minimum date restriction since maximum age is no longer validated
            dobField.removeAttribute('min');
        }
    }, []);

    return (
        <div className="form-section" id="section-1">
            <label id="labelTimestamp" htmlFor="form_timestamp">
                {t.labels.form_timestamp}
            </label>
            <input
                type="text"
                id="form_timestamp"
                name="form_timestamp"
                value={formData.form_timestamp}
                readOnly
                required
                onChange={handleInputChange}
            />

            <label id="labelEmail" htmlFor="email">
                {t.labels.email}
            </label>
            <input
                type="email"
                id="email"
                name="email"
                placeholder={t.placeholders.email}
                required
                value={formData.email}
                onChange={handleInputChange}
            />
            {errors.email && <p className="error-message">{errors.email}</p>}

            <label id="labelLanguage" htmlFor="form_language">
                {t.labels.form_language}
            </label>
            <select
                id="form_language"
                name="form_language"
                required
                value={formData.form_language}
                onChange={handleInputChange}
            >
                <option value="">{language === 'hi' ? 'भाषा चुनें' : 'Select Language'}</option>
                <option value="en">English</option>
                <option value="hi">Hindi</option>
            </select>
            {errors.form_language && <p className="error-message">{errors.form_language}</p>}

            <label id="labelFullName" htmlFor="name">
                {t.labels.name}
            </label>
            <input
                type="text"
                id="name"
                name="name"
                placeholder={t.placeholders.name}
                required
                value={formData.name}
                onChange={handleInputChange}
            />
            {errors.name && <p className="error-message">{errors.name}</p>}

            <label id="labelMobile" htmlFor="mobile">
                {t.labels.mobile}
            </label>
            <input
                type="tel"
                id="mobile"
                name="mobile"
                placeholder={t.placeholders.mobile}
                required
                value={formData.mobile}
                onChange={handleInputChange}
            />
            {errors.mobile && <p className="error-message">{errors.mobile}</p>}

            <label id="labelGender" htmlFor="gender">
                {t.labels.gender}
            </label>
            <select id="gender" name="gender" required value={formData.gender} onChange={handleInputChange}>
                <option value="">{language === 'hi' ? 'लिंग चुनें' : 'Select Gender'}</option>
                <option value="male">{language === 'hi' ? 'पुरुष' : 'Male'}</option>
                <option value="female">{language === 'hi' ? 'महिला' : 'Female'}</option>
                <option value="other">{language === 'hi' ? 'अन्य' : 'Other'}</option>
            </select>
            {errors.gender && <p className="error-message">{errors.gender}</p>}

            <label id="labelDob" htmlFor="dob">
                {t.labels.dob}
            </label>
            <input
                type="date"
                id="dob"
                name="dob"
                required
                value={formData.dob}
                onChange={handleInputChange}
            />
            {errors.dob && <p className="error-message">{errors.dob}</p>}

            <label id="labelAge" htmlFor="current_age">
                {t.labels.current_age}
            </label>
            <input
                type="number"
                id="current_age"
                name="current_age"
                placeholder={t.placeholders.current_age}
                readOnly
                value={formData.current_age}
                onChange={handleInputChange}
            />

            <label id="labelCity" htmlFor="city_of_residence">
                {t.labels.city_of_residence}
            </label>
            <select
                id="city_of_residence"
                name="city_of_residence"
                required
                value={formData.city_of_residence}
                onChange={handleInputChange}
            >
                <option value="">{language === 'hi' ? 'शहर चुनें' : 'Select City'}</option>
                {[
                    { en: 'Ahmedabad', hi: 'अहमदाबाद' },
                    { en: 'Bengaluru', hi: 'बेंगलुरु' },
                    { en: 'Bhopal', hi: 'भोपाल' },
                    { en: 'Bhubaneswar', hi: 'भुवनेश्वर' },
                    { en: 'Chandigarh', hi: 'चंडीगढ़' },
                    { en: 'Chennai', hi: 'चेन्नई' },
                    { en: 'Coimbatore', hi: 'कोयंबटूर' },
                    { en: 'Dehradun', hi: 'देहरादून' },
                    { en: 'Delhi', hi: 'दिल्ली' },
                    { en: 'Faridabad', hi: 'फरीदाबाद' },
                    { en: 'Ghaziabad', hi: 'गाजियाबाद' },
                    { en: 'Goa', hi: 'गोवा' },
                    { en: 'Guwahati', hi: 'गुवाहाटी' },
                    { en: 'Hyderabad', hi: 'हैदराबाद' },
                    { en: 'Indore', hi: 'इंदौर' },
                    { en: 'Jaipur', hi: 'जयपुर' },
                    { en: 'Jamshedpur', hi: 'जमशेदपुर' },
                    { en: 'Kanpur', hi: 'कानपुर' },
                    { en: 'Kochi', hi: 'कोच्चि' },
                    { en: 'Kolkata', hi: 'कोलकाता' },
                    { en: 'Lucknow', hi: 'लखनऊ' },
                    { en: 'Ludhiana', hi: 'लुधियाना' },
                    { en: 'Madurai', hi: 'मदुरै' },
                    { en: 'Mangalore', hi: 'मंगलौर' },
                    { en: 'Mumbai', hi: 'मुंबई' },
                    { en: 'Nagpur', hi: 'नागपुर' },
                    { en: 'Nashik', hi: 'नासिक' },
                    { en: 'Patna', hi: 'पटना' },
                    { en: 'Pune', hi: 'पुणे' },
                    { en: 'Surat', hi: 'सूरत' },
                    { en: 'Visakhapatnam', hi: 'विशाखापत्तनम' },
                    { en: 'Others', hi: 'अन्य' },
                ].map((city) => (
                    <option key={city.en} value={city.en.toLowerCase()}>
                        {language === 'hi' ? city.hi : city.en}
                    </option>
                ))}
            </select>
            {errors.city_of_residence && <p className="error-message">{errors.city_of_residence}</p>}

            {formData.city_of_residence === 'others' && (
                <div id="otherCityContainer">
                    <label id="labelOtherCity" htmlFor="otherCity">
                        {t.labels.otherCity}
                    </label>
                    <input
                        type="text"
                        id="otherCity"
                        name="otherCity"
                        placeholder={t.placeholders.otherCity}
                        value={formData.otherCity}
                        onChange={handleInputChange}
                    />
                    {errors.otherCity && <p className="error-message">{errors.otherCity}</p>}
                </div>
            )}

            <label id="labelDependents" htmlFor="number_of_dependents">
                {t.labels.number_of_dependents}
            </label>
            <select
                id="number_of_dependents"
                name="number_of_dependents"
                required
                value={formData.number_of_dependents}
                onChange={handleInputChange}
            >
                <option value="">{language === 'hi' ? 'निर्भर व्यक्तियों की संख्या चुनें' : 'Select Number of Dependents'}</option>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
            </select>
            {errors.number_of_dependents && <p className="error-message">{errors.number_of_dependents}</p>}

            <label id="labelSoleEarner" htmlFor="sole_earning_member">
                {t.labels.sole_earning_member}
            </label>
            <select
                id="sole_earning_member"
                name="sole_earning_member"
                required
                value={formData.sole_earning_member}
                onChange={handleInputChange}
            >
                <option value="">{language === 'hi' ? 'एक विकल्प चुनें' : 'Select an option'}</option>
                <option value="yes">{language === 'hi' ? 'हाँ' : 'Yes'}</option>
                <option value="no">{language === 'hi' ? 'नहीं' : 'No'}</option>
            </select>
            {errors.sole_earning_member && <p className="error-message">{errors.sole_earning_member}</p>}
        </div>
    );
};

export default BasicInformation;
