    const LiabilitiesTranslations = {
        en: {
            section_title: "Detail your existing liabilities, including loans, credit card balances, and other debts.",
            questions: {
                home_loan_outstanding: "32. What is the total outstanding amount of your Home Loan?",
                car_loan_outstanding: "33. What is the total outstanding amount of your Car Loan?",
                other_long_term_liabilities: "34. Do you have any other Long-Term Liabilities? (Education Loan, Mortgage against property, Gold Loan etc.)",
                long_term_liabilities: "35. If yes, what is the total outstanding loan amount?",
                home_loan_tenure: "36. What is the remaining tenure of your Home Loan?",
                car_loan_tenure: "37. What is the remaining tenure of your Car Loan?",
                other_loan_tenure: "38. What is the remaining tenure of other Long Term Loans, if any?",
                long_term_management: "39. How do you manage your long-term liabilities?",
                new_liabilities: "40. Are you planning to take on any new long-term liabilities in the next 12 months (e.g., buying a new home, education loan for children, car loan, etc.)?",
                additional_payments: "41. Are you making any additional payments towards your long-term liabilities to pay them off sooner?",
                short_term_liabilities_response: "42. What is the total amount of your short-term liabilities (Credit Card, Personal Loan, Bank Overdraft etc.)?",
                personal_loans_response: "43. Have you taken any personal loans in the last 12 months?",
                comfort_level_response: "44. Are you comfortable with your current level of short-term liabilities?"
            },

            errors: {
              home_loan_outstanding: "Please enter the total outstanding amount of your Home Loan.",
              car_loan_outstanding: "Please enter the total outstanding amount of your Car Loan.",
              other_long_term_liabilities: "Please indicate if you have any other Long-Term Liabilities.",
              long_term_liabilities: "Please enter the total outstanding loan amount for your other Long-Term Liabilities.",
              home_loan_tenure: "Please enter the remaining tenure of your Home Loan.",
              car_loan_tenure: "Please enter the remaining tenure of your Car Loan.",
              other_loan_tenure: "Please enter the remaining tenure of your other Long-Term Loans, if any.",
              long_term_management: "Please indicate how you manage your long-term liabilities.",
              new_liabilities: "Please indicate if you are planning to take on any new long-term liabilities in the next 12 months.",
              additional_payments: "Please indicate if you are making any additional payments towards your long-term liabilities.",
              short_term_liabilities_response: "Please enter the total amount of your short-term liabilities.",
              personal_loans_response: "Please indicate if you have taken any personal loans in the last 12 months.",
              comfort_level_response: "Please indicate if you are comfortable with your current level of short-term liabilities."
            },
            
            home_loan_outstanding: {
                "Not Applicable": "Not Applicable",
                "Less than ₹10,00,000": "Less than ₹10,00,000",
                "₹10,00,000 - ₹20,00,000": "₹10,00,000 - ₹20,00,000",
                "₹20,00,000 - ₹30,00,000": "₹20,00,000 - ₹30,00,000",
                "₹30,00,000 - ₹50,00,000": "₹30,00,000 - ₹50,00,000",
                "₹50,00,000 - ₹1,00,00,000": "₹50,00,000 - ₹1,00,00,000",
                "More than ₹1,00,00,000": "More than ₹1,00,00,000"
              },
              car_loan_outstanding: {
                "Not Applicable": "Not Applicable",
                "Less than ₹5,00,000": "Less than ₹5,00,000",
                "₹5,00,000 - ₹10,00,000": "₹5,00,000 - ₹10,00,000",
                "₹10,00,000 - ₹20,00,000": "₹10,00,000 - ₹20,00,000",
                "More than ₹20,00,000": "More than ₹20,00,000"
              },
              
              other_long_term_liabilities: {
                "Yes": "Yes",
                "No": "No"
              },
              long_term_liabilities: {
                "Not Applicable": "Not Applicable",
                "Less than ₹5,00,000": "Less than ₹5,00,000",
                "₹5,00,000 - ₹10,00,000": "₹5,00,000 - ₹10,00,000",
                "₹10,00,000 - ₹20,00,000": "₹10,00,000 - ₹20,00,000",
                "More than ₹20,00,000": "More than ₹20,00,000"
              },
              home_loan_tenure: {
                "Not Applicable": "Not Applicable",
                "Less than 5 Years": "Less than 5 Years",
                "5 Years - 10 Years": "5 Years - 10 Years",
                "10 Years - 15 Years": "10 Years - 15 Years",
                "More than 15 Years": "More than 15 Years"
              },
              car_loan_tenure: {
                "Not Applicable": "Not Applicable",
                "Less than 2 Years": "Less than 2 Years",
                "2 Years - 3 Years": "2 Years - 3 Years",
                "3 Years - 5 Years": "3 Years - 5 Years",
                "More than 5 Years": "More than 5 Years"
              },
              
              other_loan_tenure: {
                "Not Applicable": "Not Applicable",
                "Less than 2 Years": "Less than 2 Years",
                "2 Years - 3 Years": "2 Years - 3 Years",
                "3 Years - 5 Years": "3 Years - 5 Years",
                "More than 5 Years": "More than 5 Years"
              },

              long_term_management: {
                "Not Applicable": "Not Applicable",
                "I make regular payments and feel in control": "I make regular payments and feel in control",
                "I make regular payments but feel stretched": "I make regular payments but feel stretched",
                "I occasionally miss payments and struggle to keep up": "I occasionally miss payments and struggle to keep up",
                "I am actively looking to refinance or restructure my loans": "I am actively looking to refinance or restructure my loans"
              },
              new_liabilities: {
                "Yes, definitely": "Yes, definitely",
                "Possibly, if the need arises": "Possibly, if the need arises",
                "No, I do not plan to take on new liabilities": "No, I do not plan to take on new liabilities",
                "Not sure": "Not sure"
              },
              additional_payments: {
                "Not Applicable": "Not Applicable",
                "Yes, regularly": "Yes, regularly",
                "Yes, occasionally": "Yes, occasionally",
                "No, I only make the required payments": "No, I only make the required payments",
                "No, I am unable to make additional payments": "No, I am unable to make additional payments"
              },
              
              short_term_liabilities_response: {
                "Not Applicable": "Not Applicable",
                "Less than ₹1,00,000": "Less than ₹1,00,000",
                "₹1,00,000 - ₹5,00,000": "₹1,00,000 - ₹5,00,000",
                "₹5,00,000 - ₹10,00,000": "₹5,00,000 - ₹10,00,000",
                "More than ₹10,00,000": "More than ₹10,00,000"
              },
              personal_loans_response: {
                "Yes, for a planned expense": "Yes, for a planned expense",
                "Yes, for an emergency or unplanned expense": "Yes, for an emergency or unplanned expense",
                "No, I have not taken any personal loans": "No, I have not taken any personal loans"
              },
              comfort_level_response: {
                "Yes, I feel fully in control": "Yes, I feel fully in control",
                "Yes, but I would like to reduce them": "Yes, but I would like to reduce them",
                "No, I feel overwhelmed by my short-term debt": "No, I feel overwhelmed by my short-term debt",
                "Not sure": "Not sure"
              },
              
              errors: {
                home_loan_outstanding: "Please select your total outstanding Home Loan amount.",
                car_loan_outstanding: "Please select your total outstanding Car Loan amount.",
                other_long_term_liabilities: "Please indicate if you have other Long-Term Liabilities.",
                long_term_liabilities: "Please provide the total outstanding amount for other Long-Term Liabilities.",
                home_loan_tenure: "Please select the remaining tenure of your Home Loan.",
                car_loan_tenure: "Please select the remaining tenure of your Car Loan.",
                other_loan_tenure: "Please select the remaining tenure of other Long-Term Loans.",
                long_term_management: "Please select how you manage your long-term liabilities.",
                new_liabilities: "Please indicate whether you are planning to take on new long-term liabilities.",
                additional_payments: "Please specify if you are making additional payments towards your long-term liabilities.",
                short_term_liabilities_response: "Please select the total amount of your short-term liabilities.",
                personal_loans_response: "Please indicate if you have taken any personal loans in the last 12 months.",
                comfort_level_response: "Please indicate your comfort level with your current short-term liabilities."
              }
            
            
              
        },

        
            hi:  {
              "section_title": "अपने मौजूदा दायित्वों का विवरण दें, जिसमें ऋण, क्रेडिट कार्ड बैलेंस और अन्य ऋण शामिल हैं।",
              "questions": {
                "home_loan_outstanding": "32. आपके होम लोन का कुल बकाया राशि क्या है?",
                "car_loan_outstanding": "33. आपके कार लोन का कुल बकाया राशि क्या है?",
                "other_long_term_liabilities": "34. क्या आपके पास अन्य दीर्घकालिक दायित्व हैं? (शिक्षा ऋण, संपत्ति के खिलाफ बंधक, गोल्ड लोन आदि)",
                "long_term_liabilities": "35. यदि हाँ, तो अन्य दीर्घकालिक ऋण की कुल बकाया राशि क्या है?",
                "home_loan_tenure": "36. आपके होम लोन की शेष अवधि कितनी है?",
                "car_loan_tenure": "37. आपके कार लोन की शेष अवधि कितनी है?",
                "other_loan_tenure": "38. अन्य दीर्घकालिक ऋणों की शेष अवधि कितनी है, यदि कोई हो?",
                "long_term_management": "39. आप अपने दीर्घकालिक दायित्वों का प्रबंधन कैसे करते हैं?",
                "new_liabilities": "40. क्या आप अगले 12 महीनों में कोई नया दीर्घकालिक दायित्व लेने की योजना बना रहे हैं (जैसे, नया घर खरीदना, बच्चों के लिए शिक्षा ऋण, कार लोन आदि)?",
                "additional_payments": "41. क्या आप अपने दीर्घकालिक दायित्वों को शीघ्र चुकाने के लिए अतिरिक्त भुगतान कर रहे हैं?",
                "short_term_liabilities_response": "42. आपके अल्पकालिक दायित्वों की कुल राशि क्या है (क्रेडिट कार्ड, व्यक्तिगत ऋण, बैंक ओवरड्राफ्ट आदि)?",
                "personal_loans_response": "43. क्या आपने पिछले 12 महीनों में कोई व्यक्तिगत ऋण लिया है?",
                "comfort_level_response": "44. क्या आप अपने मौजूदा अल्पकालिक दायित्वों के स्तर से सहज हैं?"
              },
              errors: {
                home_loan_outstanding: "कृपया अपने होम लोन की कुल बकाया राशि दर्ज करें।",
                car_loan_outstanding: "कृपया अपने कार लोन की कुल बकाया राशि दर्ज करें।",
                other_long_term_liabilities: "कृपया बताएं कि क्या आपके पास कोई अन्य दीर्घकालिक देनदारियां हैं।",
                long_term_liabilities: "कृपया अपनी अन्य दीर्घकालिक देनदारियों की कुल बकाया राशि दर्ज करें।",
                home_loan_tenure: "कृपया अपने होम लोन की शेष अवधि दर्ज करें।",
                car_loan_tenure: "कृपया अपने कार लोन की शेष अवधि दर्ज करें।",
                other_loan_tenure: "कृपया अपनी अन्य दीर्घकालिक देनदारियों की शेष अवधि दर्ज करें, यदि कोई हो।",
                long_term_management: "कृपया बताएं कि आप अपनी दीर्घकालिक देनदारियों को कैसे प्रबंधित करते हैं।",
                new_liabilities: "कृपया बताएं कि क्या आप अगले 12 महीनों में कोई नई दीर्घकालिक देनदारियां लेने की योजना बना रहे हैं।",
                additional_payments: "कृपया बताएं कि क्या आप अपनी दीर्घकालिक देनदारियों को जल्द चुकाने के लिए कोई अतिरिक्त भुगतान कर रहे हैं।",
                short_term_liabilities_response: "कृपया अपनी अल्पकालिक देनदारियों की कुल राशि दर्ज करें।",
                personal_loans_response: "कृपया बताएं कि क्या आपने पिछले 12 महीनों में कोई व्यक्तिगत ऋण लिया है।",
                comfort_level_response: "कृपया बताएं कि क्या आप अपनी मौजूदा अल्पकालिक देनदारियों के स्तर से संतुष्ट हैं।"
              },
              
              home_loan_outstanding: {
                "Not Applicable": "लागू नहीं",
                "Less than ₹10,00,000": "₹10,00,000 से कम",
                "₹10,00,000 - ₹20,00,000": "₹10,00,000 - ₹20,00,000",
                "₹20,00,000 - ₹30,00,000": "₹20,00,000 - ₹30,00,000",
                "₹30,00,000 - ₹50,00,000": "₹30,00,000 - ₹50,00,000",
                "₹50,00,000 - ₹1,00,00,000": "₹50,00,000 - ₹1,00,00,000",
                "More than ₹1,00,00,000": "₹1,00,00,000 से अधिक"
            },
            car_loan_outstanding: {
                "Not Applicable": "लागू नहीं",
                "Less than ₹5,00,000": "₹5,00,000 से कम",
                "₹5,00,000 - ₹10,00,000": "₹5,00,000 - ₹10,00,000",
                "₹10,00,000 - ₹20,00,000": "₹10,00,000 - ₹20,00,000",
                "More than ₹20,00,000": "₹20,00,000 से अधिक"
            },
            other_long_term_liabilities: {
                "Yes": "हां",
                "No": "नहीं"
            },
            long_term_liabilities: {
                "Not Applicable": "लागू नहीं",
                "Less than ₹5,00,000": "₹5,00,000 से कम",
                "₹5,00,000 - ₹10,00,000": "₹5,00,000 - ₹10,00,000",
                "₹10,00,000 - ₹20,00,000": "₹10,00,000 - ₹20,00,000",
                "More than ₹20,00,000": "₹20,00,000 से अधिक"
            },
            home_loan_tenure: {
                "Not Applicable": "लागू नहीं",
                "Less than 5 Years": "5 साल से कम",
                "5 Years - 10 Years": "5 साल - 10 साल",
                "10 Years - 15 Years": "10 साल - 15 साल",
                "More than 15 Years": "15 साल से अधिक"
            },
            car_loan_tenure: {
                "Not Applicable": "लागू नहीं",
                "Less than 2 Years": "2 साल से कम",
                "2 Years - 3 Years": "2 साल - 3 साल",
                "3 Years - 5 Years": "3 साल - 5 साल",
                "More than 5 Years": "5 साल से अधिक"
            },
            other_loan_tenure: {
                "Not Applicable": "लागू नहीं",
                "Less than 2 Years": "2 साल से कम",
                "2 Years - 3 Years": "2 साल - 3 साल",
                "3 Years - 5 Years": "3 साल - 5 साल",
                "More than 5 Years": "5 साल से अधिक"
            },
            long_term_management: {
                "Not Applicable": "लागू नहीं",
                "I make regular payments and feel in control": "मैं नियमित भुगतान करता हूं और नियंत्रण में महसूस करता हूं",
                "I make regular payments but feel stretched": "मैं नियमित भुगतान करता हूं लेकिन खिंचाव महसूस करता हूं",
                "I occasionally miss payments and struggle to keep up": "मैं कभी-कभी भुगतान छोड़ देता हूं और पीछे रह जाता हूं",
                "I am actively looking to refinance or restructure my loans": "मैं अपने ऋणों को पुनर्वित्त या पुनर्गठन करने की सक्रिय रूप से तलाश कर रहा हूं"
            },
            new_liabilities: {
                "Yes, definitely": "हां, निश्चित रूप से",
                "Possibly, if the need arises": "संभवतः, यदि आवश्यकता होती है",
                "No, I do not plan to take on new liabilities": "नहीं, मैं नई देनदारियां लेने की योजना नहीं बना रहा हूं",
                "Not sure": "निश्चित नहीं"
            },
            additional_payments: {
                "Not Applicable": "लागू नहीं",
                "Yes, regularly": "हां, नियमित रूप से",
                "Yes, occasionally": "हां, कभी-कभी",
                "No, I only make the required payments": "नहीं, मैं केवल आवश्यक भुगतान करता हूं",
                "No, I am unable to make additional payments": "नहीं, मैं अतिरिक्त भुगतान करने में असमर्थ हूं"
            },
            short_term_liabilities_response: {
                "Not Applicable": "लागू नहीं",
                "Less than ₹1,00,000": "₹1,00,000 से कम",
                "₹1,00,000 - ₹5,00,000": "₹1,00,000 - ₹5,00,000",
                "₹5,00,000 - ₹10,00,000": "₹5,00,000 - ₹10,00,000",
                "More than ₹10,00,000": "₹10,00,000 से अधिक"
            },
            personal_loans_response: {
                "Yes, for a planned expense": "हां, एक नियोजित खर्च के लिए",
                "Yes, for an emergency or unplanned expense": "हां, एक आपातकालीन या अनियोजित खर्च के लिए",
                "No, I have not taken any personal loans": "नहीं, मैंने कोई व्यक्तिगत ऋण नहीं लिया है"
            },
            comfort_level_response: {
                "Yes, I feel fully in control": "हां, मैं पूरी तरह से नियंत्रण में महसूस करता हूं",
                "Yes, but I would like to reduce them": "हां, लेकिन मैं उन्हें कम करना चाहूंगा",
                "No, I feel overwhelmed by my short-term debt": "नहीं, मैं अपने अल्पकालिक ऋण से अभिभूत महसूस करता हूं",
                "Not sure": "निश्चित नहीं"
            },
              "errors": {
                "home_loan_outstanding": "कृपया अपने होम लोन की कुल बकाया राशि चुनें।",
                "car_loan_outstanding": "कृपया अपने कार लोन की कुल बकाया राशि चुनें।",
                "other_long_term_liabilities": "कृपया बताएं कि क्या आपके पास अन्य दीर्घकालिक दायित्व हैं।",
                "long_term_liabilities": "कृपया अन्य दीर्घकालिक दायित्वों की कुल बकाया राशि प्रदान करें।",
                "home_loan_tenure": "कृपया अपने होम लोन की शेष अवधि चुनें।",
                "car_loan_tenure": "कृपया अपने कार लोन की शेष अवधि चुनें।",
                "other_loan_tenure": "कृपया अन्य दीर्घकालिक ऋणों की शेष अवधि चुनें।",
                "long_term_management": "कृपया बताएं कि आप अपने दीर्घकालिक दायित्वों का प्रबंधन कैसे करते हैं।",
                "new_liabilities": "कृपया बताएं कि क्या आप नए दीर्घकालिक दायित्व लेने की योजना बना रहे हैं।",
                "additional_payments": "कृपया बताएं कि क्या आप अपने दीर्घकालिक दायित्वों को चुकाने के लिए अतिरिक्त भुगतान कर रहे हैं।",
                "short_term_liabilities_response": "कृपया अपने अल्पकालिक दायित्वों की कुल राशि चुनें।",
                "personal_loans_response": "कृपया बताएं कि क्या आपने पिछले 12 महीनों में कोई व्यक्तिगत ऋण लिया है।",
                "comfort_level_response": "कृपया बताएं कि क्या आप अपने मौजूदा अल्पकालिक दायित्वों से सहज हैं।"
              }
            },
          
          
        
};

export default LiabilitiesTranslations;