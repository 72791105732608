// BasicInformationTranslations.js
const BasicInformationTranslations = {
    en: {
        labels: {
            form_timestamp: 'Timestamp',
            email: 'Email',
            name: 'Full Name',
            mobile: 'Mobile Number',
            gender: 'Gender',
            dob: 'Date of Birth',
            current_age: 'Age',
            city_of_residence: 'City of Residence',
            otherCity: 'Please specify your city',
            number_of_dependents: 'Number of Dependents',
            sole_earning_member: 'Are you the sole EARNING MEMBER of your Family?',
            form_language: 'Form Language',
        },
        placeholders: {
            email: 'Enter your email address',
            name: 'Enter your full name',
            mobile: 'Enter your mobile number',
            current_age: 'Your age will be calculated',
            otherCity: 'Enter your city',
        },
        errors: {
            emailRequired: 'Email is required.',
            emailInvalid: 'Invalid email format.',
            languageRequired: 'Language is required.',
            nameRequired: 'Name is required.',
            nameInvalid: 'Name must be at least 4 characters and contain no numbers.',
            mobileRequired: 'Mobile number is required.',
            mobileInvalid: 'Invalid mobile number format.',
            genderRequired: 'Gender is required.',
            dobRequired: 'Date of birth is required.',
            cityRequired: 'City is required.',
            otherCityInvalid: 'Please specify your city with at least 3 characters.',
            dependentsRequired: 'Number of dependents is required.',
            soleEarnerRequired: 'Please specify if you are the sole earning member.',
                        // ... existing errors ...
            dobInvalid: 'Invalid date of birth.',
            dobAgeInvalid: 'Age must be between 23 and 50 years.',
            
        },
        data: {
            genderOptions: [
                { value: 'Male', label: 'Male' },
                { value: 'Female', label: 'Female' },
                { value: 'Other', label: 'Other' },
            ],
            cityOptions: ['Ahmedabad', 'Bengaluru', 'Bhopal', 'Bhubaneswar', 'Chandigarh', 'Chennai', 'Coimbatore', 'Dehradun', 'Delhi', 'Faridabad', 'Ghaziabad', 'Goa', 'Guwahati', 'Hyderabad', 'Indore', 'Jaipur', 'Jamshedpur', 'Kanpur', 'Kochi', 'Kolkata', 'Lucknow', 'Ludhiana', 'Madurai', 'Mangalore', 'Mumbai', 'Nagpur', 'Nashik', 'Patna', 'Pune', 'Surat', 'Visakhapatnam', 'Others']
        },
    },
    hi: {
        labels: {
            form_timestamp: 'टाइमस्टैम्प',
            email: 'ईमेल',
            name: 'पूरा नाम',
            mobile: 'मोबाइल नंबर',
            gender: 'लिंग',
            dob: 'जन्म तिथि',
            current_age: 'उम्र',
            city_of_residence: 'निवास का शहर',
            otherCity: 'कृपया अपना शहर निर्दिष्ट करें',
            number_of_dependents: 'निर्भर व्यक्तियों की संख्या',
            sole_earning_member: 'क्या आप अपने परिवार के एकमात्र कमाने वाले सदस्य हैं?',
            form_language: 'फॉर्म भाषा',
        },
        placeholders: {
            email: 'अपना ईमेल पता दर्ज करें',
            name: 'अपना पूरा नाम दर्ज करें',
            mobile: 'अपना मोबाइल नंबर दर्ज करें',
            current_age: 'आपकी उम्र स्वचालित रूप से गणना होगी',
            otherCity: 'अपना शहर दर्ज करें',
        },
        errors: {
            emailRequired: 'ईमेल आवश्यक है।',
            emailInvalid: 'अमान्य ईमेल प्रारूप।',
            languageRequired: 'भाषा आवश्यक है।',
            nameRequired: 'नाम आवश्यक है।',
            nameInvalid: 'नाम कम से कम 4 अक्षरों का होना चाहिए और इसमें कोई संख्या नहीं होनी चाहिए।',
            mobileRequired: 'मोबाइल नंबर आवश्यक है।',
            mobileInvalid: 'अमान्य मोबाइल नंबर प्रारूप।',
            genderRequired: 'लिंग आवश्यक है।',
            dobRequired: 'जन्म तिथि आवश्यक है।',
            cityRequired: 'शहर आवश्यक है।',
            otherCityInvalid: 'कृपया कम से कम 3 अक्षरों वाले अपने शहर को निर्दिष्ट करें।',
            dependentsRequired: 'निर्भर लोगों की संख्या आवश्यक है।',
            soleEarnerRequired: 'कृपया बताएं कि क्या आप एकमात्र कमाने वाले सदस्य हैं।',
            dobInvalid: 'अमान्य जन्म तिथि।',
            dobAgeInvalid: 'आयु 23 और 50 वर्ष के बीच होनी चाहिए।',
        },
        

    },
   /* hi: {
        labels: {
            टाइमस्टैम्प: 'टाइमस्टैम्प',
            ईमेल: 'ईमेल',
            पूरानाम: 'पूरा नाम',
            मोबाइलनंबर: 'मोबाइल नंबर',
            लिंग: 'लिंग',
            जन्मतिथि: 'जन्म तिथि',
            उम्र: 'उम्र',
            निवासकाशहर: 'निवास का शहर',
            कृपयाअपनाशहरनिर्दिष्टकरें: 'कृपया अपना शहर निर्दिष्ट करें',
            निर्भरव्यक्तियोंकीसंख्या: 'निर्भर व्यक्तियों की संख्या',
            क्याआपअपनेपरिवारकेएकमात्रकमानेवालेसदस्यहैं: 'क्या आप अपने परिवार के एकमात्र कमाने वाले सदस्य हैं?',
            फॉर्मभाषा: 'फॉर्म भाषा',
        },
        placeholders: {
            अपनाईमेलपतादर्जकरें: 'अपना ईमेल पता दर्ज करें',
            अपनापूरानामतुर्जकरें: 'अपना पूरा नाम दर्ज करें',
            अपनामोबाइलनंबरदर्जकरें: 'अपना मोबाइल नंबर दर्ज करें',
            आपकीउम्रस्वचालितरूपसेगणनाहोगी: 'आपकी उम्र स्वचालित रूप से गणना होगी',
            अपनाशहरदर्जकरें: 'अपना शहर दर्ज करें',
        },
        errors: {
            ईमेलआवश्यकहै: 'ईमेल आवश्यक है।',
            अमान्यईमेलप्रारूप: 'अमान्य ईमेल प्रारूप।',
            भाषाआवश्यकहै: 'भाषा आवश्यक है।',
            नामआवश्यकहै: 'नाम आवश्यक है।',
            नामकमसेकम4अक्षरोंकाहोनाचाहिएऔरइसमेंकोईसंख्यानहींहोनीचाहिए: 'नाम कम से कम 4 अक्षरों का होना चाहिए और इसमें कोई संख्या नहीं होनी चाहिए।',
            मोबाइलनंबरआवश्यकहै: 'मोबाइल नंबर आवश्यक है।',
            अमान्यमोबाइलनंबरप्रारूप: 'अमान्य मोबाइल नंबर प्रारूप।',
            लिंगआवश्यकहै: 'लिंग आवश्यक है।',
            जन्मतिथिआवश्यकहै: 'जन्म तिथि आवश्यक है।',
            शहरआवश्यकहै: 'शहर आवश्यक है।',
            कृपयाकमसेकम3अक्षरोंवालेअपनेशहरकोनिर्दिष्टकरें: 'कृपया कम से कम 3 अक्षरों वाले अपने शहर को निर्दिष्ट करें।',
            निर्भरलोगोंकीसंख्याआवश्यकहै: 'निर्भर लोगों की संख्या आवश्यक है।',
            कृपयाबताएंकिक्याआपएकमात्रकमानेवालेसदस्यहैं: 'कृपया बताएं कि क्या आप एकमात्र कमाने वाले सदस्य हैं।',
            अमान्यजन्मतिथि: 'अमान्य जन्म तिथि।',
            आयु23और50वर्षकेबीचहोनीचाहिए: 'आयु 23 और 50 वर्ष के बीच होनी चाहिए।',
        },
         
    },
    */
};

export default BasicInformationTranslations;